import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyAMkU3RBWwd1njb5-vC00RLq-A-I68NDoE",
  authDomain: "mushroom-33332.firebaseapp.com",
  projectId: "mushroom-33332",
  storageBucket: "mushroom-33332.appspot.com",
  messagingSenderId: "1031212882156",
  appId: "1:1031212882156:web:32c56e6379d817770d2dc9",
  measurementId: "G-KRH0EQF0R5"
};

firebase.initializeApp(firebaseConfig);


const analytics = getAnalytics();
logEvent(analytics, 'notification_received');

var database = firebase.firestore();;

export default database;
